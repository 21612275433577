import { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import LicensesActions from './components/LicensesActions/LicensesActions';
import ExportDetailsModal from './components/ExportDetailsModal/ExportDetailsModal';
import { useAppDispatch, useAppSelector } from 'store';
import LicensingTable from './components/LicensingTable/LicensingTable';
import { getLicensesReports, getReportStatusData } from 'store/slices/analytics';
import { AngleRightIcon } from 'assets/img';
import { Button } from 'components';
import { useHistory } from 'react-router-dom';
import styles from './LicensingDetails.module.scss';
import { notify } from 'utils';
import { IDomain } from 'interfaces';
import { getDomainStatus } from 'helpers';

interface Props {
  primaryDomain: string;
  domain: IDomain;
  userRole: string;
  isCompanyPage: boolean;
  hideBack?: boolean;
}

const LicensingDetails = ({ primaryDomain, domain, userRole, isCompanyPage, hideBack }: Props) => {
  const dispatch = useAppDispatch();
  const { isDomainNotActivated } = getDomainStatus(domain);
  const { licenses } = useAppSelector((state) => state.analytics);
  const { isLoading, data } = useAppSelector((state) => state.analytics.getLicensesReportsRequest);
  const { data: reportStatus } = useAppSelector((state) => state.analytics.getReportStatusDataRequest);
  const { isFastGenerate } = useAppSelector((state) => state.analytics);
  const history = useHistory();
  const goBack = () => {
    if (isCompanyPage) {
      return history.replace('/company-management/analytics?tab=licensing');
    } else {
      return history.push(`/customer-management/${domain.dnsName}/analytics?tab=licensing`);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(
          getLicensesReports({ domainId: domain.id, licenseType: selectedLicenseType, _background: !!data }),
        ).unwrap();
        if (!reportStatus) {
          await dispatch(getReportStatusData({ domainId: domain.id })).unwrap();
        }
      } catch (err: any) {
        notify.error(err.message);
      }
    };
    fetchData();
  }, []);

  const [selectedLicenseType, setSelectedLicenseType] = useState(isDomainNotActivated ? 'BitLocker' : 'SecureRDP');

  const allLicensesCount = licenses.reduce((prev, cur) => prev + cur.licensesCount, 0);

  const [showExportDetailsModal, setShowExportDetailsModal] = useState(false);

  const toggleExportDetailsModal = () => {
    setShowExportDetailsModal(!showExportDetailsModal);
  };

  const saveFile = ({ downloadLink }: { downloadLink: string }) => {
    const fileName = downloadLink.split('/').slice(-1).toString();
    saveAs(`${downloadLink}`, `${fileName}`);
  };

  const onChangeLicenseType = async (selectedLicenseTypeNew: string) => {
    try {
      setSelectedLicenseType(selectedLicenseTypeNew);
      await dispatch(getLicensesReports({ domainId: domain.id, licenseType: selectedLicenseTypeNew })).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  return (
    <div>
      {!hideBack && (
        <div className={styles.licensingTitleWrap}>
          <Button icon={<AngleRightIcon />} variant="link" onClick={goBack} className={styles.licensingTitleIcon} />
          <h1 className={styles.licensingTitle}>Licensing</h1>
        </div>
      )}
      <LicensesActions
        isCompanyPage={isCompanyPage}
        selectedLicenseType={selectedLicenseType}
        onChangeLicenseType={onChangeLicenseType}
        isLoading={isLoading}
        licensesCount={allLicensesCount}
        userRole={userRole}
        setShowExportDetailsModal={setShowExportDetailsModal}
        isFastGenerate={isFastGenerate}
        saveFile={saveFile}
        isDomainNotActivated={isDomainNotActivated}
      />

      <LicensingTable
        licenses={licenses}
        primaryDomain={primaryDomain}
        isCompanyPage={isCompanyPage}
        userRole={userRole}
        selectedLicenseType={selectedLicenseType}
      />

      {showExportDetailsModal && (
        <ExportDetailsModal
          primaryDomain={primaryDomain}
          licenses={licenses}
          showExportDetailsModal={showExportDetailsModal}
          toggleExportDetailsModal={toggleExportDetailsModal}
          userRole={userRole}
          saveFile={saveFile}
          isCompanyPage={isCompanyPage}
        />
      )}
    </div>
  );
};

export default LicensingDetails;

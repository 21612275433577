import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { IDomain, IQueryConfig } from 'interfaces';
import Users from '../../tabs/Users/Users';
import Computers from '../../tabs/Computers/Computers';
import Licensing from '../../tabs/Licensing/Licensing';
import Assignments from '../../tabs/Assignments/Assignments';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getAnalyticsAuthentication,
  getAnalyticsComputers,
  getAnalyticsLicenses,
  getAnalyticsRdpAttempts,
  getAnalyticsUsers,
  IAnalyticsLoginStatsPayload,
  updateSelectedRange,
  getLicensesReports,
  getAssignmentsReports,
} from 'store/slices/analytics';
import { notify } from 'utils';
import { getDomainADStatus, getDomainStatus } from 'helpers';
import ComputersEntraID from 'pages/Analytics/tabs/Computers/ComputersEntraID';
import styles from './AnalyticsTabs.module.scss';
import LicensingDetails from 'pages/Analytics/pages/LicensingDetails/LicensingDetails';
import { corpAdmin, corpTechAdmin, custAdmin, techAdmin } from 'consts';

interface Props {
  domain: IDomain;
  userRole: string;
  isCompanyPage: boolean;
}

const AnalyticsTabs = ({ domain, userRole, isCompanyPage }: Props) => {
  const { isDomainAzure } = getDomainADStatus(domain);
  const { isDomainNotActivated } = getDomainStatus(domain);
  const dispatch = useAppDispatch();

  const { hasActiveCustomerDomains } = useAppSelector((state) => state.user.user);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload: IAnalyticsLoginStatsPayload = {
          domainId: domain.id,
          start: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
          end: new Date(new Date().getTime()),
          userIds: [],
        };
        const queryConfig: IQueryConfig = {
          pageNumber: 1,
          pageSize: 10,
          orderBy: 'atUtc desc',
          searchQuery: '',
        };

        dispatch(updateSelectedRange('Last 7 days'));
        if (!isDomainNotActivated) {
          await Promise.all([
            dispatch(getAnalyticsUsers({ domainId: domain.id })).unwrap(),
            dispatch(getAnalyticsAuthentication({ payload: payload, query: queryConfig })).unwrap(),
            dispatch(getAnalyticsRdpAttempts({ payload: payload, query: queryConfig })).unwrap(),
            dispatch(getAnalyticsLicenses({ type: 'rdp', domainId: domain.id })).unwrap(),
            dispatch(getAnalyticsComputers({ domainId: domain.id })).unwrap(),
            dispatch(getLicensesReports({ domainId: domain.id, licenseType: 'SecureRDP' })).unwrap(),
            dispatch(getAssignmentsReports({ domainId: domain.id })).unwrap(),
          ]);
        }
      } catch (err: any) {
        notify.error(err.message);
      }
    };
    fetchData();
  }, []);

  const { search } = useLocation();
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    const params = new URLSearchParams(search);
    if (params.get('tab') === 'computers') {
      setTabIndex(1);
    }
    if (params.get('tab') === 'licensing') {
      setTabIndex(2);
    }
  }, [search]);

  const onTabSelect = (index: number) => {
    const params = new URLSearchParams(search);
    if (params.get('tab')) {
      params.delete('tab');
      const url = document.location.href;
      window.history.pushState({}, '', url.split('?')[0]);
    }
    setTabIndex(index);
  };

  const isAdmin =
    userRole === custAdmin || userRole === corpAdmin || userRole === corpTechAdmin || userRole === techAdmin;

  return (
    <div className={styles.analytics}>
      <div className={styles.analyticsTitleWrap}>
        <h1 className={styles.analyticsTitle}>Analytics</h1>
      </div>
      <Tabs selectedIndex={tabIndex} onSelect={onTabSelect}>
        <TabList>
          {!isDomainNotActivated && <Tab>Users</Tab>}
          {!isDomainNotActivated && <Tab>Computers</Tab>}
          <Tab>Licensing</Tab>
          {!isDomainNotActivated && <Tab>Assignments</Tab>}
        </TabList>
        {!isDomainNotActivated && (
          <TabPanel>
            <Users domain={domain} />
          </TabPanel>
        )}
        {!isDomainNotActivated && (
          <TabPanel>
            {isDomainAzure ? <ComputersEntraID domain={domain} isDomainAzure={isDomainAzure} /> : <Computers />}
          </TabPanel>
        )}
        <TabPanel>
          {isDomainNotActivated || isAdmin || !hasActiveCustomerDomains || !isCompanyPage ? (
            <LicensingDetails
              primaryDomain={domain.dnsName}
              userRole={userRole}
              isCompanyPage={isCompanyPage}
              domain={domain}
              hideBack
            />
          ) : (
            <Licensing domain={domain} userRole={userRole} />
          )}
        </TabPanel>
        {!isDomainNotActivated && (
          <TabPanel>
            <Assignments domain={domain} userRole={userRole} isCompanyPage={isCompanyPage} />
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
};

export default AnalyticsTabs;

import { useEffect, useState } from 'react';
import braintree from 'braintree-web-drop-in';
import BraintreeDropin from 'braintree-dropin-react';
import { Button, Loader, StatusMessage } from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import { changePaymentMethod, getBillingToken } from 'store/slices/billing';
import { notify } from 'utils';
import styles from './Billing.module.scss';
import { SerializedError } from '@reduxjs/toolkit';

const Billing = () => {
  const dispatch = useAppDispatch();
  const { isLoading: isDisabled } = useAppSelector((state) => state.billing.changePaymentMethodRequest);
  const { data, isError, error } = useAppSelector((state) => state.billing.getBillingTokenRequest);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getBillingToken());
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const renderSubmitButton = (args: { onClick: () => void; isDisabled: boolean; text: string }) => (
    <Button
      type="button"
      size="40"
      id="billing-set-primary"
      className={styles.setCardBtn}
      onClick={args.onClick}
      isDisabled={args.isDisabled || isDisabled}>
      {args.text}
    </Button>
  );

  const handlePaymentMethod = async ({ nonce }: { nonce: string }) => {
    if (nonce) {
      try {
        await dispatch(changePaymentMethod(nonce)).unwrap();
        notify.success('Payment method updated successfully');
      } catch (err: any) {
        const message =
          err.message === 'Server error while changing payment method'
            ? 'You can not set same payment method more then once in one session'
            : err.message;
        notify.error(message);
      }
    }
  };

  if (isLoading) {
    return (
      <div className={styles.billing}>
        <Loader id="loading-billing" />
      </div>
    );
  }

  if (isError) {
    return <StatusMessage error>{(error as SerializedError).message}</StatusMessage>;
  }

  return (
    <div className={styles.billing}>
      <h3 className={styles.billingHeader}>Billing</h3>
      <div className={styles.braintreeWrapper}>
        {data?.clientToken && (
          <BraintreeDropin
            submitButtonText="Set card as primary"
            braintree={braintree}
            authorizationToken={data?.clientToken}
            renderSubmitButton={renderSubmitButton}
            handlePaymentMethod={handlePaymentMethod}
            paypal={{ flow: 'vault', singleUse: false }}
          />
        )}
      </div>
    </div>
  );
};

export default Billing;

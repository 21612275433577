import { useState } from 'react';
import { Button, StatusMessage } from 'components';
import { useAppSelector } from 'store';
import ChangeEmail from '../ChangeEmail/ChangeEmail';
import styles from './AccountInfo.module.scss';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
  isUpdateEmailHidden: boolean;
}

const AccountInfo = ({ isUpdateEmailHidden }: Props) => {
  const { myAccount } = useAppSelector((state) => state.myProfile);
  const { isError, error } = useAppSelector((state) => state.myProfile.getMyAccountDataRequest);

  const [isUpdateEmailOpen, setIsUpdateEmailOpen] = useState(false);

  const closeUpdateEmail = () => {
    setIsUpdateEmailOpen(false);
  };

  if (isError) {
    return <StatusMessage error>{(error as SerializedError).message}</StatusMessage>;
  }

  if (!myAccount) {
    return <StatusMessage>No data</StatusMessage>;
  }

  return (
    <>
      <div className={styles.row}>
        <div className={styles.rowTitle}>Email Address</div>
        <div className={styles.rowValue} id="profile-email-address">
          {myAccount.email}
        </div>
        <div className={styles.rowAction}>
          {!isUpdateEmailHidden && (
            <Button
              type="button"
              size="32"
              variant="secondary"
              onClick={() => setIsUpdateEmailOpen(!isUpdateEmailOpen)}
              id="toogle-edit-email-address"
              style={{ transition: 'none' }}>
              {isUpdateEmailOpen ? 'Close' : 'Update Email'}
            </Button>
          )}
        </div>
        {myAccount.requestedEmail && (
          <span className={styles.rowActionMessage}>
            Waiting for verification, email sent to {myAccount.requestedEmail}
          </span>
        )}
      </div>

      {isUpdateEmailOpen && <ChangeEmail myAccount={myAccount} closeUpdateEmail={closeUpdateEmail} />}

      <div className={styles.row}>
        <div className={styles.rowTitle}>Account Details</div>
        <div className={styles.rowValue}>{myAccount.displayName}</div>
        <div className={styles.rowAction} />
      </div>
    </>
  );
};

export default AccountInfo;

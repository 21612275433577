import { useState, useEffect } from 'react';
import { getUserRole } from 'utils';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Loader, StatusMessage } from 'components';
import { corpAdmin, corpTechAdmin, corpTechUser, freePersonalUser, personalUser } from 'consts';
import { useAppDispatch, useAppSelector } from 'store';
import { getSubscriptions } from 'store/slices/subscriptions';
import { ICorporateSubscription } from 'interfaces';
import useModal from 'hooks/useModal';
import PrimaryDomain from './pages/PrimaryDomain/PrimaryDomain';
import CustomerDomain from './pages/CustomerDomain/CustomerDomain';
import ReactivateModal from './components/ReactivateModal/ReactivateModal';
import SubscriptionsHeader from './components/SubscriptionsHeader/SubscriptionsHeader';
import styles from './Subscriptions.module.scss';
import { SerializedError } from '@reduxjs/toolkit';

const Subscriptions = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { error, isError } = useAppSelector((state) => state.subscriptions.getSubscriptionsRequest);
  const { licenses } = useAppSelector((state) => state.subscriptions);

  const [isLoading, setIsLoading] = useState(true);

  const { isOpen: showReactivateModal, closeModal: closeReactivateModal, openModal: openReactivateModal } = useModal();

  const role = getUserRole(user);
  const isPersonal = [personalUser, freePersonalUser].includes(role);
  const isNoTabs = [corpAdmin, corpTechAdmin, corpTechUser, personalUser, freePersonalUser].includes(role);

  const fetchData = async () => {
    await dispatch(getSubscriptions(isPersonal)).unwrap();
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isError) {
    return (
      <div className={styles.subscriptions}>
        <StatusMessage error>{(error as SerializedError).message}</StatusMessage>
      </div>
    );
  }

  return (
    <div className={styles.subscriptions}>
      <SubscriptionsHeader isPersonal={isPersonal} openReactivateModal={openReactivateModal} role={role} />
      <div className={styles.subscriptionsContent}>
        {isLoading ? (
          <Loader id="loading-subscriptions" />
        ) : (
          <div className={styles.subscriptionsTabs}>
            <Tabs>
              <div className={styles.subscriptionsTabsWrap}>
                <h2 className={styles.subscriptionsTabsTitle}>Subscriptions</h2>
                <TabList className={`react-tabs__tab-list ${isNoTabs ? 'no-tabs' : ''}`}>
                  <Tab disabled={!(licenses as ICorporateSubscription)?.customerDomains?.length && !isPersonal}>
                    <span id="primary-domain">Primary Domain</span>
                  </Tab>
                  <Tab disabled={!(licenses as ICorporateSubscription)?.customerDomains?.length && !isPersonal}>
                    <span id="customer-domains">Customer Domains</span>
                  </Tab>
                </TabList>
              </div>
              <TabPanel>
                <PrimaryDomain role={role} email={user.email} />
              </TabPanel>
              <TabPanel>
                <CustomerDomain />
              </TabPanel>
            </Tabs>
          </div>
        )}
      </div>
      {showReactivateModal && <ReactivateModal isOpen={showReactivateModal} onRequestClose={closeReactivateModal} />}
    </div>
  );
};

export default Subscriptions;

import { useState } from 'react';
import { Link, StatusMessage } from 'components';
import { notify } from 'utils';
import MfaHeader from './components/MfaHeader/MfaHeader';
import ToggleEnableMfaModal from './components/ToggleEnableMfaModal/ToggleEnableMfaModal';
import ResetMfaModal from './components/ResetMfaModal/ResetMfaModal';
import { useAppDispatch, useAppSelector } from 'store';
import { IDomain } from 'interfaces';
import { IMfaUser, changeUserMfaStatus } from 'store/slices/securityMgmt/mfaMgmt';
import styles from './MfaMgmt.module.scss';
import MfaMgmtTable from './components/MfaMgmtTable/MfaMgmtTable';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
  domain: IDomain;
}
export type TypeAction = 'Enable MFA' | 'Disable MFA' | 'Reset MFA';
export interface IModalData {
  type: TypeAction;
  usersToUpdate: IMfaUser[];
}

const MfaMgmt = ({ domain }: Props) => {
  const { error, isError } = useAppSelector((store) => store.mfaMgmt.getMfaDataRequest);
  const { mfaData } = useAppSelector((store) => store.mfaMgmt);
  const dispatch = useAppDispatch();

  const [selectedUsers, setSelectedUsers] = useState<IMfaUser[]>([]);
  const [modalData, setModalData] = useState<IModalData | null>(null);
  const [isResetPhone, setIsResetPhone] = useState<boolean>(true);

  const [showEnableMfaModal, setShowEnableMfaModal] = useState<boolean>(false);
  const [showDisableMfaModal, setShowDisableMfaModal] = useState<boolean>(false);
  const [showResetMfaModal, setShowResetMfaModal] = useState<boolean>(false);

  const toggleModal = (type: TypeAction) => {
    if (type === 'Disable MFA') {
      setShowDisableMfaModal(!showDisableMfaModal);
    }
    if (type === 'Enable MFA') {
      setShowEnableMfaModal(!showEnableMfaModal);
    }
    if (type === 'Reset MFA') {
      setIsResetPhone(true);
      setShowResetMfaModal(!showResetMfaModal);
    }
  };

  const onModalOpen = (type: TypeAction, user: IMfaUser, isHeaderBtn?: boolean) => {
    const usersToUpdate = isHeaderBtn ? selectedUsers : [{ ...user }];

    toggleModal(type);
    setModalData({ type, usersToUpdate });
  };

  const onChangeMfa = async (type: TypeAction, userData: IModalData, isResetMfa?: boolean) => {
    try {
      const isEnableMfa = type !== 'Disable MFA';
      const data = userData ? [...userData.usersToUpdate] : modalData ? modalData.usersToUpdate : selectedUsers;
      const lastWord = data.length > 1 ? 'users' : 'user';

      await dispatch(
        changeUserMfaStatus({ users: data, domainId: domain.id, isResetMfa, isEnableMfa, isResetPhone }),
      ).unwrap();

      const message = isResetMfa
        ? 'MFA reset'
        : `MFA is ${isEnableMfa ? 'enabled' : 'disabled'} for selected ${lastWord}`;
      toggleModal(userData.type);
      notify.success(message);
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      // if (!userData) {
      //   toggleModal(userData.type);
      // }
      if (selectedUsers.length) setSelectedUsers([]);
    }
  };

  if (isError) {
    return <StatusMessage error>{(error as SerializedError).message}</StatusMessage>;
  }

  if (mfaData.isNoData) {
    return (
      <StatusMessage header="No users reporting in">
        <p>
          Please check Active Directory or{' '}
          <Link href="https://www.trugrid.com/docs/usersnotreportingin" external bold>
            add users
          </Link>
        </p>
      </StatusMessage>
    );
  }

  return (
    <>
      <MfaHeader
        domainId={domain.id}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        onModalOpen={onModalOpen}
      />

      <div className={styles.content}>
        {!mfaData?.data?.length && !mfaData.queryConfig?.searchQuery ? (
          <StatusMessage header="No items available">Change view selection</StatusMessage>
        ) : (
          <MfaMgmtTable
            domainId={domain.id}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            onModalOpen={onModalOpen}
            showEnableMfaModal={showEnableMfaModal}
          />
        )}
      </div>

      {(showDisableMfaModal || showEnableMfaModal) && (
        <ToggleEnableMfaModal
          modalData={modalData!}
          toggleShowMfaModal={toggleModal}
          onChangeMfa={onChangeMfa}
          showEnableMfaModal={showEnableMfaModal}
          showDisableMfaModal={showDisableMfaModal}
        />
      )}

      {showResetMfaModal && (
        <ResetMfaModal
          showResetMfaModal={showResetMfaModal}
          toggleShowResetMfaModal={toggleModal}
          modalData={modalData!}
          onChangeMfa={onChangeMfa}
          setIsResetPhone={setIsResetPhone}
          isResetPhone={isResetPhone}
        />
      )}
    </>
  );
};

export default MfaMgmt;

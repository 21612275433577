import cn from 'classnames';
import { ExclamationIcon, MinusIcon, PlusIcon } from 'assets/img';
import { Button } from 'components';
import { corpAdmin, spAdmin } from 'consts';
import styles from './InputControls.module.scss';
import { ICorporateSubscription } from 'interfaces';
import { IWarning } from '../../PrimaryDomain';
import { useAppSelector } from 'store';

type LicenseType = 'numberOfRdpLicenses' | 'numberOfBitLockerLicenses';
type PurchasedByType = 'totalPurchasedRdpLicenses' | 'totalPurchasedBitLockerLicenses';
type CanceledByType = 'canceledRdpLicenses' | 'canceledBitLockerLicenses';
type AllocatedByType = 'totalAllocatedRdpLicenses' | 'totalAllocatedBitLockerLicenses';

interface Props {
  licensesState: ICorporateSubscription;
  role: string;
  setLicensesState: React.Dispatch<React.SetStateAction<ICorporateSubscription>>;
  warning: IWarning;
  setWarning: React.Dispatch<React.SetStateAction<IWarning>>;
  minimumLicensesCount: number;
  typeFunc: LicenseType;
  id: string;
}

const InputControls = ({
  warning,
  setWarning,
  licensesState,
  setLicensesState,
  role,
  typeFunc,
  id,
  minimumLicensesCount,
}: Props) => {
  const licenses = useAppSelector((state) => state.subscriptions.licenses as ICorporateSubscription);

  const onIncrease = (licenseType: LicenseType) => {
    const licensesNew: ICorporateSubscription = JSON.parse(JSON.stringify(licensesState));
    const totalPurchasedByType: PurchasedByType = `totalPurchased${licenseType.slice(8)}` as PurchasedByType;
    const totalCanceledByType: CanceledByType = `canceled${licenseType.slice(8)}` as CanceledByType;

    if (licensesNew[totalPurchasedByType as keyof ICorporateSubscription] === 1000000) {
      if (!warning.isActive) {
        setWarning({
          isActive: true,
          field: [licenseType],
          content: (
            <>
              <ExclamationIcon />
              You can't buy more than 1000000 licenses
            </>
          ),
        });
        setTimeout(() => setWarning({ isActive: false }), 3000);
      }
      return;
    }

    if (
      licensesNew[totalCanceledByType as keyof ICorporateSubscription] >
      licenses[totalCanceledByType as keyof ICorporateSubscription]
    ) {
      licensesNew[totalCanceledByType] -= 1;
    }
    licensesNew[totalPurchasedByType] += 1;
    licensesNew.spDomain[licenseType] += 1;

    setLicensesState(licensesNew);
  };

  const onDecrease = (licenseType: LicenseType) => {
    const licensesNew: ICorporateSubscription = JSON.parse(JSON.stringify(licensesState));
    const totalPurchasedByType: PurchasedByType = `totalPurchased${licenseType.slice(8)}` as PurchasedByType;
    const totalCanceledByType: CanceledByType = `canceled${licenseType.slice(8)}` as CanceledByType;

    if (licensesNew[totalPurchasedByType] > minimumLicensesCount) {
      if (licensesNew.spDomain[licenseType] <= licenses.spDomain[licenseType]) {
        licensesNew[totalCanceledByType] += 1;
      }
      licensesNew[totalPurchasedByType] -= 1;
      licensesNew.spDomain[licenseType] -= 1;

      setLicensesState(licensesNew);
    } else if (!warning.isActive) {
      setWarning({
        isActive: true,
        field: [licenseType],
        content: (
          <>
            <ExclamationIcon />
            You can not reduce the minimum number of licenses
          </>
        ),
      });
      setTimeout(() => setWarning({ isActive: false }), 3000);
    }
  };

  const onInputChange = (licenseType: LicenseType, isValid: boolean, value: string) => {
    if (isValid) {
      const licensesNew: ICorporateSubscription = JSON.parse(JSON.stringify(licensesState));
      const totalPurchasedByType: PurchasedByType = `totalPurchased${licenseType.slice(8)}` as PurchasedByType;
      const totalCanceledByType: CanceledByType = `canceled${licenseType.slice(8)}` as CanceledByType;
      const totalAllocatedByType: AllocatedByType = licensesState[
        `totalAllocated${licenseType.slice(8)}` as keyof ICorporateSubscription
      ] as AllocatedByType;
      const initialAmountOfLicenses = licensesState.spDomain[licenseType];

      if (initialAmountOfLicenses > +value) {
        licensesNew[totalCanceledByType] += initialAmountOfLicenses - +value;
      }
      if (
        initialAmountOfLicenses <= +value &&
        licensesNew[totalCanceledByType as keyof ICorporateSubscription] >
          licenses[totalCanceledByType as keyof ICorporateSubscription]
      ) {
        const diff = +value - licensesNew.spDomain[licenseType];

        if (diff > licensesNew[totalCanceledByType]) {
          licensesNew[totalCanceledByType] = licenses[totalCanceledByType];
        } else {
          licensesNew[totalCanceledByType] -= diff;
        }
      }
      licensesNew[totalPurchasedByType] = +value + +totalAllocatedByType;
      licensesNew.spDomain[licenseType] = value === '' ? +value : +value;

      setLicensesState(licensesNew);
    }
  };

  const onInputBlur = (licenseType: LicenseType, value: string) => {
    if (value === '') {
      const licensesNew = JSON.parse(JSON.stringify(licensesState));
      licensesNew.spDomain[licenseType] = 0;
      setLicensesState(licensesNew);
    }
  };

  return (
    <div className={styles.controlsItem}>
      <h5 className={styles.controlsItemTitle}>{id === 'subs-primary-rdp-input' ? 'SecureRDP' : 'BitLocker'}</h5>
      <div className={styles.controlsItemWrap}>
        {[spAdmin, corpAdmin].includes(role) && !licenses.isAutomatedLicensingEnabled && (
          <Button
            variant="link"
            className={styles.controlsItemButton}
            id="decreasing-licenses-count"
            onClick={() => onDecrease(typeFunc)}
            isDisabled={!+licensesState.spDomain[typeFunc]}>
            <MinusIcon />
          </Button>
        )}
        <span className={styles.controlsItemWrapInputWrap}>
          <input
            id={id}
            type="text"
            pattern="[0-9]*"
            maxLength={7}
            className={cn(styles.controlsItemWrapInput, {
              [styles.warning]: warning.isActive && warning.field?.includes(typeFunc),
            })}
            disabled={![spAdmin, corpAdmin].includes(role) || licenses.isAutomatedLicensingEnabled}
            value={licensesState.spDomain[typeFunc]}
            onChange={(e) => onInputChange(typeFunc, e.target.validity.valid, e.target.value)}
            onBlur={(e) => onInputBlur(typeFunc, e.target.value)}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
              e.key === 'Enter' && (e.target as HTMLInputElement).blur()
            }
          />
        </span>
        {[spAdmin, corpAdmin].includes(role) && !licenses.isAutomatedLicensingEnabled && (
          <Button
            variant="link"
            className={styles.controlsItemButton}
            id="increasing-licenses-count"
            onClick={() => onIncrease(typeFunc)}>
            <PlusIcon />
          </Button>
        )}
      </div>
    </div>
  );
};

export default InputControls;

import { useState, useEffect } from 'react';
import { DomainStatusMsg, Loader, StatusMessage } from 'components';
import { checkIsCompanyPage, getDomainStatus, isSentryDeleted } from 'helpers';
import { notify } from 'utils';
import UpdateUserModal from './components/UpdateUserModal/UpdateUserModal';
import RolesHeader from './components/RolesHeader/RolesHeader';
import NoUsers from './components/NoUsers/NoUsers';
import RolesMgmtTable from './components/RolesMgmtTable/RolesMgmtTable';
import { getPageTitle, getSuccessfulMessage } from './utils/rolesMgmtUtils';
import { useAppDispatch, useAppSelector } from 'store';
import { IRoleUser, getRolesMgmtData, updateUserRoleAssignments } from 'store/slices/rolesMgmt';
import styles from './RolesMgmt.module.scss';
import { IDomain } from 'interfaces';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
  domain: IDomain;
  isDomainNotActivated: boolean;
  userRole: string;
}

const RolesMgmt = ({ domain, isDomainNotActivated, userRole }: Props) => {
  const dispatch = useAppDispatch();
  const isCompanyPage = checkIsCompanyPage();

  const { rolesMgmtData: assignments } = useAppSelector((state) => state.rolesMgmt);
  const { isLoading, isError, error } = useAppSelector((state) => state.rolesMgmt.getRolesMgmtDataRequest);

  const [showUpdateUserModal, setShowUpdateUserModal] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<IRoleUser[]>([]);

  const toggleUpdateUserModal = (): void => {
    setShowUpdateUserModal(!showUpdateUserModal);
  };

  const { isDomainActive } = getDomainStatus(domain);
  const sentryDeleted: boolean = isSentryDeleted(domain);

  const fetchData = async (): Promise<void> => {
    await dispatch(getRolesMgmtData({ domainId: domain.id })).unwrap();
  };

  useEffect(() => {
    if (isDomainActive && !sentryDeleted) {
      fetchData();
    }
  }, []);

  if (isLoading) {
    return <Loader id="loading-roles-management" />;
  }

  if (isDomainNotActivated || sentryDeleted) {
    return <DomainStatusMsg domainData={domain} isSentryDeleted={sentryDeleted} pageName="Roles Management" />;
  }

  if (isError) {
    return <StatusMessage error>{(error as SerializedError).message}</StatusMessage>;
  }

  if (!assignments?.data.length && !assignments?.queryConfig.searchQuery) {
    return <NoUsers />;
  }

  const onUpdateUsers = async (): Promise<void> => {
    try {
      const userIdList: string[] = selectedUsers.map((user) => user.userId);
      await dispatch(updateUserRoleAssignments({ domainId: domain.id, userIdList })).unwrap();
      setSelectedUsers([]);
      const successfulMessage: string = getSuccessfulMessage(isCompanyPage, domain, selectedUsers);
      notify.success(successfulMessage);
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      if (showUpdateUserModal) toggleUpdateUserModal();
    }
  };

  const onUpdateUserRoles = async () => {
    if (selectedUsers.some((i) => !i.isMfaEnabled && !i.isAssigned)) {
      toggleUpdateUserModal();
    } else {
      await onUpdateUsers();
    }
  };

  const unselectAllUsers = () => {
    setSelectedUsers([]);
  };

  const pageTitle = getPageTitle(userRole, isCompanyPage);

  return (
    <div className={styles.roles}>
      <h3 className={styles.rolesTitle}>{pageTitle}</h3>
      <RolesHeader
        domainId={domain.id}
        selectedUsers={selectedUsers}
        unselectAllUsers={unselectAllUsers}
        onUpdateUserRoles={() => void onUpdateUserRoles()}
      />
      <RolesMgmtTable
        assignments={assignments}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        domainId={domain.id}
      />

      {showUpdateUserModal && (
        <UpdateUserModal
          showUpdateUserModal={showUpdateUserModal}
          toggleUpdateUserModal={toggleUpdateUserModal}
          selectedUsers={selectedUsers}
          onUpdateUsers={onUpdateUsers}
        />
      )}
    </div>
  );
};

export default RolesMgmt;

import { useMemo, useState } from 'react';
import cn from 'classnames';
import { saveAs } from 'file-saver';
import { DownloadIcon, FileIcon } from 'assets/img';
import { Button } from 'components';
import { dateFormatterUtc, getDomainADStatus } from 'helpers';
import ReactTooltip from 'react-tooltip';
import { notify } from 'utils';
import { corpAdmin, corpTechAdmin, custAdmin } from 'consts';
import styles from './Assignments.module.scss';
import GenerateReportModal from './components/GenerateReportModal/GenerateReportModal';
import { useAppDispatch, useAppSelector } from 'store';
import { IDomain, ILicenseReport } from 'interfaces';
import {
  generateApplicationAssignmentsReport,
  generateMachineAssignmentsReport,
  generateUserAssignmentsReport,
} from 'store/slices/reports';
import { ReportLoading } from 'pages/Reports/Reports';
import { SerializedError } from '@reduxjs/toolkit';

export enum ReportTypes {
  userAssignments = 'userAssignments',
  machineAssignmnets = 'machineAssignmnets',
  appAssignments = 'appAssignments',
}

export type CheckBoxesChecked = {
  usersUserGroups: boolean;
  application: boolean;
  computersComputerGroups: boolean;
};

interface IReportItem {
  title: string;
  description: string;
  type: ReportTypes;
  report?: string | null;
  reportDate?: string | null;
  isLoading: boolean;
}

interface Props {
  domain: IDomain;
  userRole: string;
  isCompanyPage: boolean;
  reportLoading: ReportLoading;
  setReportLoading: React.Dispatch<React.SetStateAction<ReportLoading>>;
}

const Assignments = ({ domain, userRole, isCompanyPage, reportLoading, setReportLoading }: Props) => {
  const dispatch = useAppDispatch();

  const { licenses } = useAppSelector((state) => state.reports);
  const { reports } = useAppSelector((state) => state.reports);

  const primaryDomainData = licenses.find((el) => el.domainName.toLowerCase() === domain.dnsName.toLowerCase());
  const [checkedDomains, setCheckedDomains] = useState<ILicenseReport[]>(primaryDomainData ? [primaryDomainData] : []);

  const isDomainAzure = useMemo(() => licenses.every((el) => getDomainADStatus(el).isDomainAzure), [licenses]);
  const isAllDomainsAppsDisabled = useMemo(() => licenses.every((el) => !el.isAppPublishingEnabled), [licenses]);
  const isLeastOneDomainHasApps = useMemo(() => licenses.some((el) => el.isAppPublishingEnabled), [licenses]);
  const isOnlyOneDomainHasApps = useMemo(
    () => licenses.filter((el) => el.isAppPublishingEnabled).length === 1,
    [licenses],
  );
  const disableAppColumn = useMemo(() => checkedDomains.every((el) => !el?.isAppPublishingEnabled), [checkedDomains]);

  const [checkBoxesChecked, setCheckBoxesChecked] = useState<CheckBoxesChecked>({
    usersUserGroups: true,
    application: !disableAppColumn,
    computersComputerGroups: true,
  });

  const toggleCheckBoxesChecked = (key: string) => {
    setCheckBoxesChecked({
      ...checkBoxesChecked,
      [key]: !checkBoxesChecked[key as keyof CheckBoxesChecked],
    });
  };

  const [showExportDetailsModal, setShowExportDetailsModal] = useState(false);
  const [reportType, setReportType] = useState<ReportTypes | null>(null);
  const toggleShowExportDetailsModal = (type: ReportTypes) => {
    if (showExportDetailsModal) {
      setShowExportDetailsModal(false);
      setReportType(null);
      setCheckBoxesChecked({
        usersUserGroups: true,
        application: !disableAppColumn,
        computersComputerGroups: true,
      });
    } else {
      setCheckedDomains(primaryDomainData ? [primaryDomainData] : []);
      setReportType(type);
      setShowExportDetailsModal(true);
    }
  };

  const { isAppPublishingEnabled } = domain;

  const saveFile = (link: string) => {
    const fileName = link.split('/').slice(-1).toString();
    saveAs(`${link}`, `${fileName}`);
  };

  const items: IReportItem[] = useMemo(
    () => [
      {
        title: isDomainAzure ? 'Users' : 'Users or User Groups',
        description: isDomainAzure
          ? 'This report shows resources assigned to specific users'
          : 'This report shows resources assigned to specific users or user groups',
        type: ReportTypes.userAssignments,
        report: reports?.userAssignments?.downloadLink,
        reportDate: reports?.userAssignments?.createdAt,
        isLoading: reportLoading.userAssignments,
      },
      {
        title: isDomainAzure ? 'Computers' : 'Computers or Computer Groups',
        description: isDomainAzure
          ? 'This report shows list of Users assigned to computers'
          : 'This report shows list of Users or User Groups assigned to computers or computer groups',
        type: ReportTypes.machineAssignmnets,
        report: reports?.machineAssignmnets?.downloadLink,
        reportDate: reports?.machineAssignmnets?.createdAt,
        isLoading: reportLoading.machineAssignmnets,
      },
      {
        title: 'Applications',
        description: 'This report shows list of Users or User Groups assigned to specific applications',
        type: ReportTypes.appAssignments,
        report: reports?.appAssignments?.downloadLink,
        reportDate: reports?.appAssignments?.createdAt,
        isLoading: reportLoading.appAssignments,
      },
    ],
    [isDomainAzure, reports, reportLoading],
  );

  if (isAllDomainsAppsDisabled) {
    items.splice(2, 2);
  }

  const confirmResult = async (type: ReportTypes, item?: ILicenseReport) => {
    try {
      setReportLoading((prevValue) => ({ ...prevValue, [type]: true }));
      const domainIds = item ? [item.domainId] : checkedDomains.map((el) => el.domainId);
      if (type === ReportTypes.userAssignments) {
        const exportReport =
          userRole === custAdmin || userRole === corpAdmin || userRole === corpTechAdmin || !isCompanyPage;
        const includeMachines = isDomainAzure || exportReport ? true : checkBoxesChecked.computersComputerGroups;
        const includeApplications = checkBoxesChecked.application;
        const data = { domainIds, includeMachines, includeApplications };
        const response = await dispatch(generateUserAssignmentsReport({ domainId: domain.id, ...data })).unwrap();
        saveFile(response.downloadLink);
      }
      if (type === ReportTypes.machineAssignmnets) {
        const response = await dispatch(generateMachineAssignmentsReport({ domainId: domain.id, domainIds })).unwrap();
        saveFile(response.downloadLink);
      }
      if (type === ReportTypes.appAssignments) {
        const response = await dispatch(
          generateApplicationAssignmentsReport({ domainId: domain.id, domainIds }),
        ).unwrap();
        saveFile(response.downloadLink);
      }
      notify.success('Your report is generated');
      setReportLoading((prevValue) => ({ ...prevValue, [type]: false }));
    } catch (err: any) {
      if ((err as SerializedError).code === 'ECONNABORTED') {
        setReportLoading((prevValue) => ({ ...prevValue, [type]: true }));
        notify.success(
          <div>
            <div>Your request is in progress</div>
            <div>Please refresh this page in a few minutes</div>
          </div>,
        );
      } else {
        setReportLoading((prevValue) => ({ ...prevValue, [type]: false }));
        notify.error(err?.response?.data?.message || err?.message);
      }
    }
  };

  const buttonAction = (type: ReportTypes) => {
    const exportReport =
      userRole === custAdmin || userRole === corpAdmin || userRole === corpTechAdmin || !isCompanyPage;
    const exportUserReport = !(exportReport && type === ReportTypes.userAssignments && isAppPublishingEnabled);
    if (type === ReportTypes.appAssignments && isOnlyOneDomainHasApps) {
      const item = licenses.find((el) => el.isAppPublishingEnabled);
      setCheckedDomains(item ? [item] : []);
      return confirmResult(type, item);
    }
    if (exportReport && exportUserReport) {
      confirmResult(type);
    } else {
      toggleShowExportDetailsModal(type);
    }
  };

  return (
    <>
      <div className={styles.assignments}>
        <ul className={styles.assignmentsList}>
          {items.map((item) => (
            <li key={item.title} className={styles.assignmentsListItem}>
              <div>
                <h3 className={styles.assignmentsListTitle}>{item.title}</h3>
                <p className={styles.assignmentsListDescription}>{item.description}</p>
              </div>
              <div className={styles.assignmentsListActions}>
                <Button
                  className={cn(styles.assignmentsReportButton, {
                    [styles.loading]: item.isLoading,
                    [styles.noReport]: !item.report,
                  })}
                  id="generate-report"
                  variant="secondary"
                  size="32"
                  isLoading={item.isLoading}
                  onClick={() => buttonAction(item.type)}
                  icon={!item.isLoading ? <FileIcon /> : undefined}>
                  {item.isLoading ? 'Generating Report...' : 'Generate Report'}
                </Button>
                {item.report && (
                  <>
                    <Button
                      type="button"
                      id="download-report"
                      variant="link"
                      onClick={() => saveFile(item.report!)}
                      className={styles.licensesActionsButtonsDownload}>
                      <span data-for="download" data-tip={dateFormatterUtc(item.reportDate!, true)}>
                        <DownloadIcon />
                      </span>
                    </Button>
                    <ReactTooltip
                      id="download"
                      type="light"
                      place="top"
                      offset={{ left: 60, top: 5 }}
                      effect="solid"
                      className={styles.tooltip}
                    />
                  </>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      {showExportDetailsModal && reportType && primaryDomainData && (
        <GenerateReportModal
          domainId={domain.id}
          isOpen={showExportDetailsModal}
          onRequestClose={() => toggleShowExportDetailsModal(reportType)}
          type={reportType}
          isDomainAzure={isDomainAzure}
          userRole={userRole}
          isCompanyPage={isCompanyPage}
          isLeastOneDomainHasApps={isLeastOneDomainHasApps}
          checkBoxesChecked={checkBoxesChecked}
          toggleCheckBoxesChecked={toggleCheckBoxesChecked}
          checkedDomains={checkedDomains}
          setCheckedDomains={setCheckedDomains}
          primaryDomainData={primaryDomainData}
          setReportLoading={setReportLoading}
          setCheckBoxesChecked={setCheckBoxesChecked}
        />
      )}
    </>
  );
};

export default Assignments;

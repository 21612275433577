import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './Link.module.scss';

interface Props {
  href?: string;
  id?: string;
  to?: any;
  title?: string;
  external?: boolean;
  bold?: boolean;
  className?: any;
  style?: any;
  onClick?: any;
  children: any;
}

const LinkComponent = ({ href, id, to, title, external, bold, className, style, onClick, children }: Props) => {
  if (external) {
    return (
      <a
        id={id}
        href={href}
        target={external && '_blank'}
        title={title}
        rel={external && 'noopener noreferrer'}
        className={cn(styles.link, {
          [styles.linkBold]: bold,
          [className]: className,
        })}
        style={style}>
        {children}
      </a>
    );
  }

  return (
    <Link
      onClick={onClick}
      to={to}
      id={id}
      className={cn(styles.link, {
        [styles.linkBold]: bold,
        [className]: className,
      })}
      style={style}>
      {children}
    </Link>
  );
};

export default LinkComponent;

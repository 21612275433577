import { Button, Table } from 'components';
import NoComputers from '../NoComputers/NoComputers';
import { getDesktopAssignments, IResourceAssignmentsItem } from 'store/slices/resourceAssignments';
import { IModalData } from 'pages/ResourceAssignments/ResourceAssignments';
import { useAppDispatch, useAppSelector } from 'store';
import { IDomain, IQueryConfig } from 'interfaces';
import styles from './DesktopAssignmentsTable.module.scss';

interface Props {
  openManageAssignmentsModal: (data: IModalData) => void;
  domain: IDomain;
}

const DesktopAssignmentsTable = ({ openManageAssignmentsModal, domain }: Props) => {
  const dispatch = useAppDispatch();
  const deskAssignments = useAppSelector((state) => state.resourceAssignments.desktopAssignmentsData);

  const queryViewBy = deskAssignments?.queryConfig?.viewBy as string;

  const columns = [
    {
      name: queryViewBy === 'Users' ? 'User' : 'Computer',
      sortable: 'name',
      width: '38%',
      data: ({ name }: { name: string }) => (name ? <div className="truncate-text">{name}</div> : 'N/A'),
    },
    {
      name: queryViewBy === 'Users' ? 'Domain / UPN' : 'Domain',
      sortable: 'domainName',
      width: '38%',
      data: ({ domainName }: { domainName: string }) => domainName || 'N/A',
    },
    {
      name: `Assigned ${queryViewBy === 'Users' ? 'Computers' : 'Users'}`,
      width: '24%',
      data: (assigment: IResourceAssignmentsItem) => (
        <div>
          <span>{assigment.assignmentsCount}</span>
          <Button
            type="button"
            id="manage-desktop-assignments"
            variant="primary"
            size="32"
            className={styles.assignmentsButton}
            onClick={() =>
              openManageAssignmentsModal({
                type: 'manage',
                assignType: 'Computer',
                domainId: domain.id,
                viewBy: queryViewBy,
                resourceName: assigment.name || 'N/A',
                resourceId: assigment.resourceId,
                isPooled: assigment.isPooled,
              })
            }>
            Manage assignments
          </Button>
        </div>
      ),
    },
  ];

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getDesktopAssignments({ domainId: domain.id, query: query })).unwrap();
  };

  return (
    <Table<IResourceAssignmentsItem>
      columns={columns}
      data={deskAssignments}
      dataKey="resourceId"
      noData={<NoComputers />}
      dispatchAction={dispatchAction}
      className={styles.assignmentsTable}
    />
  );
};

export default DesktopAssignmentsTable;

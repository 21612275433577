import { corpAdmin, corpTechAdmin } from 'consts';
import TooltipInfo from '../../../../components/TooltipInfo/TooltipInfo';
import styles from './TotalLicenses.module.scss';
import { ICorporateSubscription } from 'interfaces';
import { calculateTotal } from 'pages/Profile/pages/Subscriptions/utils/checkLicenses';

interface Props {
  licensesState: ICorporateSubscription;
  role: string;
  canceledLicensesMsg: string;
  minimumLicensesCount: number;
}

const TotalLicenses = ({ licensesState, role, canceledLicensesMsg, minimumLicensesCount }: Props) => {
  const { numberOfFreeLicenses, canceledBitLockerLicenses, canceledRdpLicenses } = licensesState;

  const totalPurchasedLicenses = calculateTotal(role, licensesState, minimumLicensesCount);

  return (
    <div className={styles.info}>
      <div className={styles.infoSection}>
        <div className={styles.infoSectionText}>
          Total Purchased Licenses: <b>{totalPurchasedLicenses}</b>
        </div>
        {![corpAdmin, corpTechAdmin].includes(role) && (
          <TooltipInfo
            name="totalPurchased"
            content="Includes total allocated to customer domains"
            position="right"
            icon="question"
            isHtml={false}
          />
        )}
      </div>

      {![corpAdmin, corpTechAdmin].includes(role) && Number(numberOfFreeLicenses) > 0 && (
        <div className={styles.infoSection}>
          <div className={styles.infoSectionText}>
            SecureRDP Free Licenses: <b>{numberOfFreeLicenses}</b>
          </div>
        </div>
      )}

      {!!canceledBitLockerLicenses && (
        <div className={styles.infoSection}>
          <div className={styles.infoSectionText}>
            Canceled BitLocker Licenses: <b>{canceledBitLockerLicenses}</b>
          </div>
          <TooltipInfo name="totalCanceled" content={canceledLicensesMsg} position="right" icon="question" isHtml />
        </div>
      )}

      {!!canceledRdpLicenses && (
        <div className={styles.infoSection}>
          <div className={styles.infoSectionText}>
            Canceled SecureRDP Licenses: <b>{canceledRdpLicenses}</b>
          </div>
          <TooltipInfo name="totalCanceled" content={canceledLicensesMsg} position="right" icon="question" isHtml />
        </div>
      )}
    </div>
  );
};

export default TotalLicenses;

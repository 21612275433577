import { corpAdmin, corpTechAdmin } from 'consts';
import { ICorporateSubscription } from 'interfaces';

export const checkLicenses = (
  licenses: ICorporateSubscription,
  licensesState: ICorporateSubscription,
  minimumLicensesCount: number,
) => {
  const isRdpChanged = licenses.totalPurchasedRdpLicenses !== licensesState.totalPurchasedRdpLicenses;
  const isBitLockerChanged = licenses.totalPurchasedBitLockerLicenses !== licensesState.totalPurchasedBitLockerLicenses;

  const isCanceledFromRdp = licensesState.totalPurchasedRdpLicenses === 0;
  const isCanceledFromBitlocker = licensesState.totalPurchasedBitLockerLicenses === 0;

  const isLessRdp =
    isRdpChanged &&
    ((licensesState.totalPurchasedRdpLicenses < minimumLicensesCount && !isCanceledFromRdp) ||
      (licensesState.totalPurchasedBitLockerLicenses === 0 && !isCanceledFromBitlocker) ||
      (licensesState.totalPurchasedRdpLicenses === 0 && licensesState.totalPurchasedBitLockerLicenses === 0));

  const isLessBitlocker =
    isBitLockerChanged &&
    ((licensesState.totalPurchasedBitLockerLicenses < minimumLicensesCount && !isCanceledFromBitlocker) ||
      (licensesState.totalPurchasedRdpLicenses === 0 && !isCanceledFromRdp) ||
      (licensesState.totalPurchasedBitLockerLicenses === 0 && licensesState.totalPurchasedRdpLicenses === 0));

  const isMoreRdp = isRdpChanged && licensesState.totalPurchasedRdpLicenses > 1000000;
  const isMoreBitlocker = isBitLockerChanged && licensesState.totalPurchasedBitLockerLicenses > 1000000;

  return { isLessRdp, isLessBitlocker, isMoreRdp, isMoreBitlocker };
};

export interface ILicensesState
  extends Pick<
    ICorporateSubscription,
    | 'totalPurchasedBitLockerLicenses'
    | 'totalAllocatedRdpLicenses'
    | 'totalPurchasedRdpLicenses'
    | 'isAutomatedLicensingEnabled'
    | 'numberOfFreeLicenses'
  > {}

export const calculateTotal = (role: string, licensesState: ILicensesState, minimumLicensesCount: number) => {
  const {
    totalPurchasedBitLockerLicenses,
    totalAllocatedRdpLicenses,
    totalPurchasedRdpLicenses,
    isAutomatedLicensingEnabled,
    numberOfFreeLicenses,
  } = licensesState;

  let totalRdpLicenses = totalPurchasedRdpLicenses + totalPurchasedBitLockerLicenses;

  if (isAutomatedLicensingEnabled && ![corpAdmin, corpTechAdmin].includes(role)) {
    const primaryDomainLicenses = totalPurchasedRdpLicenses - totalAllocatedRdpLicenses;
    if (primaryDomainLicenses < numberOfFreeLicenses) {
      totalRdpLicenses = totalAllocatedRdpLicenses;
    } else {
      totalRdpLicenses =
        totalPurchasedRdpLicenses - numberOfFreeLicenses < 10 ? 10 : totalPurchasedRdpLicenses - numberOfFreeLicenses;
    }

    if (totalRdpLicenses < minimumLicensesCount) {
      totalRdpLicenses = minimumLicensesCount;
    }
    totalRdpLicenses = totalPurchasedBitLockerLicenses + totalRdpLicenses;
  }

  return totalRdpLicenses;
};

import { useEffect } from 'react';
import { StatusMessage } from 'components';
import ReactTooltip from 'react-tooltip';
import DevicesSearchFilter from '../../components/SearchFilter/SearchFilter';
import EncryptDetailsModal from './components/EncryptDetailsModal/EncyptDetailsModal';
import EncryptPassModal from './components/EncryptPassModal/EncryptPassModal';
import NoLicensesMessage from 'pages/BitLockerMgmt/components/NoLicensesMessage/NoLicensesMessage';
import { useAppSelector } from 'store';
import { checkIsCustTechAdmin, checkIsVisibleNoLicensesMsg } from 'pages/BitLockerMgmt/utils/bitLockerUtils';
import EncryptionsTable from './components/EncryptionsTable/EncryptionsTable';
import useModal from 'hooks/useModal';
import { IBitlockerEncryption, IBitlockerEncryptionDetails } from 'store/slices/bitLockerMgmt';
import styles from './Encryptions.module.scss';
import { SerializedError } from '@reduxjs/toolkit';

export interface EncryptDetailsModalData {
  hostname: string;
  discs: IBitlockerEncryptionDetails[];
  deviceId: string;
}

interface Props {
  domainId: string;
  userRole: string;
}

const Encryptions = ({ domainId, userRole }: Props) => {
  const { bitlockerEncryptionData: encrypts } = useAppSelector((state) => state.bitLockerMgmt);
  const { bitlockerSubscriptionStatus } = useAppSelector((state) => state.bitLockerMgmt);
  const { error, isError } = useAppSelector((state) => state.bitLockerMgmt.getBitlockerDevicesRequest);

  const {
    isOpen: isEcryptPassModalOpen,
    openModal: openEcryptPassModal,
    closeModal: closeEcryptPassModal,
    modalData: ecryptPassModalData,
  } = useModal<IBitlockerEncryption>();

  const {
    isOpen: isEcryptDetailsModalOpen,
    openModal: openEcryptDetailsModal,
    closeModal: closeEcryptDetailsModal,
    modalData: ecryptPassDetailsData,
  } = useModal<EncryptDetailsModalData>();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (isError) {
    return <StatusMessage error>{(error as SerializedError).message}</StatusMessage>;
  }

  const devicesWithoutLicenses = encrypts?.pageMeta.DevicesWithoutLicenses;
  const isCustTechAdmin = checkIsCustTechAdmin(userRole);
  const isVisibleNoLicensesMsg = checkIsVisibleNoLicensesMsg(userRole);

  return (
    <div className={styles.encrypts}>
      {!bitlockerSubscriptionStatus && <div className={styles.devicesTabBackdrop} />}
      {!!devicesWithoutLicenses && isVisibleNoLicensesMsg && (
        <NoLicensesMessage devicesWithoutLicenses={devicesWithoutLicenses} isCustTechAdmin={isCustTechAdmin} />
      )}
      {encrypts && <DevicesSearchFilter devices={encrypts} domainId={domainId} isEncryptions />}

      <EncryptionsTable
        domainId={domainId}
        openEcryptPassModal={openEcryptPassModal}
        openEcryptDetailsModal={openEcryptDetailsModal}
      />

      <ReactTooltip id="info-tooltip" type="light" effect="solid" delayShow={200} />

      {isEcryptDetailsModalOpen && ecryptPassDetailsData && (
        <EncryptDetailsModal
          isOpen={isEcryptDetailsModalOpen}
          onRequestClose={closeEcryptDetailsModal}
          modalData={ecryptPassDetailsData}
        />
      )}
      {isEcryptPassModalOpen && ecryptPassModalData && (
        <EncryptPassModal
          isOpen={isEcryptPassModalOpen}
          onRequestClose={closeEcryptPassModal}
          modalData={ecryptPassModalData}
        />
      )}
    </div>
  );
};

export default Encryptions;

import { StatusMessage } from 'components';
import { corpTechAdmin, spTechAdmin, spTechUser, techAdmin } from 'consts';
import EmptyPages from '../EmptyPages/EmptyPages';
import { IDomain } from 'interfaces';
import { useAppSelector } from 'store';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
  userRole: string;
  domain: IDomain;
  children: React.ReactNode;
}

const DomainsList = ({ userRole, domain, children }: Props) => {
  const { myDomainMachines } = useAppSelector((state) => state.workspace);
  const { isError, error } = useAppSelector((state) => state.workspace.getMyDomainMachinesRequest);

  if (isError) {
    return <StatusMessage error>{(error as SerializedError).message}</StatusMessage>;
  }

  if (!myDomainMachines) {
    return null;
  }

  let isEmptyPage = !myDomainMachines.data?.length && !myDomainMachines.queryConfig?.searchQuery;

  if ([spTechAdmin, spTechUser, corpTechAdmin].includes(userRole)) {
    isEmptyPage =
      (myDomainMachines.queryConfig.assigned?.toLowerCase() === 'all' &&
        !myDomainMachines.pageMeta?.AllMachinesCount) ||
      (myDomainMachines.queryConfig.assigned?.toLowerCase() === 'my' &&
        !myDomainMachines.pageMeta?.AssignedMachinesCount);
  }

  if (userRole === techAdmin) {
    isEmptyPage = !myDomainMachines.pageMeta?.AssignedMachinesCount;
  }

  if (isEmptyPage) {
    return <EmptyPages domain={domain} userRole={userRole} myDomain={myDomainMachines} />;
  }

  return children;
};

export default DomainsList;

import { useState } from 'react';
import { Alert, Button } from 'components';
import { copyToClipboard } from 'utils';
import { CopyIcon, DownloadIcon, PassHideIcon, PassShowIcon } from 'assets/img';
import { IDomain } from 'interfaces';
import styles from './AlertNoSentryInstalled.module.scss';

interface Props {
  domain: IDomain;
}

const AlertNoSentryInstalled = ({ domain }: Props) => {
  const [isHidden, setIsHidden] = useState(true);
  const toggleShowCode = () => setIsHidden(!isHidden);

  const copyActivationCode = () => {
    copyToClipboard(domain.domainActivationCode ?? domain.activationCode, 'The code copied to clipboard');
  };

  return (
    <div className={styles.alertNoSentryInstalled}>
      <Alert type="warning">
        No Sentry agent currently installed. Please&nbsp;
        <a href="https://www.trugrid.com/downloads/sentry" className={styles.alertNoSentryInstalledButton}>
          <DownloadIcon /> download
        </a>
        &nbsp;Trugrid Sentry with activation code&nbsp;
        <div className={styles.alertNoSentryInstalledWrap}>
          <div className={styles.alertNoSentryInstalledInput} id="activation-code">
            {!isHidden ? domain.activationCode : '•••••••••'}
          </div>
          <div className={styles.alertNoSentryInstalledButtons}>
            <Button
              type="button"
              id="copy-activation-code"
              variant="link"
              icon={isHidden ? <PassShowIcon /> : <PassHideIcon />}
              className={styles.alertNoSentryInstalledButtonIcon}
              onClick={toggleShowCode}
            />
            <Button
              type="button"
              id="copy-activation-code"
              variant="link"
              icon={<CopyIcon />}
              onClick={copyActivationCode}
              className={styles.alertNoSentryInstalledButtonIcon}
            />
          </div>
        </div>
      </Alert>
    </div>
  );
};

export default AlertNoSentryInstalled;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getDetailedReportStatus } from 'helpers';
import { createExtraReducersForResponses, createHttpRequestInitResult, http } from 'helpers/http';
import {
  IGenerateLicensingReport,
  IGenerateUserAssignmentsReportPayload,
  IGetLoginReportsPayload,
  IHttpRequestResult,
} from 'interfaces';
import { IAssignmentsReports, ILicenseReport, ILoginReport, IReportStatusData, IUsersReport } from 'interfaces';
import { returnFileFromResponse } from 'utils';

const LOGIN_REQUEST_TIMEOUT = 5000;

export const getAssignmentsReports = createAsyncThunk('reports/getAssignmentsReports', async (domainId: string) => {
  const response: AxiosResponse<IAssignmentsReports> = await http.get(
    `/api/reports/GetAssignmentsReportsStatus?domainId=${domainId}`,
  );
  const { isReportGenerated, isReportGenerating } = getDetailedReportStatus(response.status);
  if (isReportGenerated) {
    return response.data;
  }
  if (isReportGenerating) {
    return false;
  }
});

export const getReportStatusData = createAsyncThunk('reports/getReportStatusData', async (domainId: string) => {
  const response: AxiosResponse<IReportStatusData> = await http.get(
    `/api/reports/DetailedReportStatus?domainId=${domainId}`,
  );
  return response.data;
});

export const getLoginReports = createAsyncThunk(
  'reports/getLoginReports',
  async (options: IGetLoginReportsPayload, thunkAPI) => {
    try {
      const response: AxiosResponse<ILoginReport> = await http.post('/api/reports/logins', options, {
        timeout: LOGIN_REQUEST_TIMEOUT,
      });
      return response.data;
    } catch (err: any) {
      if (err.response.status === 'ECONNABORTED') {
        return [];
      } else {
        return thunkAPI.rejectWithValue(err);
      }
    }
  },
);

export const getUsersReports = createAsyncThunk('reports/getUsersReports', async (domainId: string) => {
  const response: AxiosResponse<IUsersReport[]> = await http.post('/api/reports/employees', { domainId });
  return response.data;
});

export const getLicensesReports = createAsyncThunk(
  'reports/getLicensesReports',
  async ({ domainId, licenseType }: { domainId: string; licenseType: string }) => {
    const response: AxiosResponse<ILicenseReport> = await http.post('/api/reports/licenses', {
      domainId,
      licenseType,
    });
    return response.data;
  },
);

export const downloadAuthReport = createAsyncThunk('reports/downloadAuthReport', async (options: any) => {
  const response: AxiosResponse = await http.post('/api/ActivityReport/Prepare', options);
  return returnFileFromResponse(response);
});

export const generateUserAssignmentsReport = createAsyncThunk(
  'reports/generateUserAssignmentsReport',
  async ({ domainId, domainIds, includeMachines, includeApplications }: IGenerateUserAssignmentsReportPayload) => {
    const response: AxiosResponse<IReportStatusData> = await http.post(
      `/api/reports/GenerateUserAssignmentsReport?domainId=${domainId}`,
      {
        domainIds,
        includeMachines,
        includeApplications,
      },
      { timeout: 10000 },
    );
    return response.data;
  },
);

export const generateMachineAssignmentsReport = createAsyncThunk(
  'reports/generateMachineAssignmentsReport',
  async ({ domainId, domainIds }: Partial<IGenerateUserAssignmentsReportPayload>) => {
    const response: AxiosResponse<IReportStatusData> = await http.post(
      `/api/reports/GenerateMachineAssignmentsReport?domainId=${domainId}`,
      { domainIds },
      { timeout: 10000 },
    );
    return response.data;
  },
);

export const generateApplicationAssignmentsReport = createAsyncThunk(
  'reports/generateApplicationAssignmentsReport',
  async ({ domainId, domainIds }: Partial<IGenerateUserAssignmentsReportPayload>) => {
    const response: AxiosResponse<IReportStatusData> = await http.post(
      `/api/reports/GenerateApplicationAssignmentsReport?domainId=${domainId}`,
      { domainIds },
      { timeout: 10000 },
    );
    return response.data;
  },
);

export const generateLicensingReport = createAsyncThunk(
  'reports/generateLicensingReport',
  async ({ domainId, domainIds, isMFAStatusChecked, isLicenseChecked, isADGroupChecked }: IGenerateLicensingReport) => {
    const response: AxiosResponse<IReportStatusData> = await http.post(
      `/api/reports/GenerateDetailedReport?domainId=${domainId}`,
      {
        domainIds,
        IncludeMfaStatus: isMFAStatusChecked,
        IncludeLicenseIssueDate: isLicenseChecked,
        IncludeDirectGroup: isADGroupChecked,
      },
      { timeout: 10000 },
    );
    return response.data;
  },
);

interface IReportsState {
  reports: IAssignmentsReports | null;
  licenses: ILicenseReport[];
  users: IUsersReport[];
  selectedUsers: string[];
  selectedRange: string;
  getAssignmentsReportsRequest: IHttpRequestResult<IAssignmentsReports>;
  getReportStatusDataRequest: IHttpRequestResult<IReportStatusData>;
  getLoginReportsRequest: IHttpRequestResult<ILoginReport>;
  getUsersReportsRequest: IHttpRequestResult<IUsersReport[]>;
  getLicensesReportsRequest: IHttpRequestResult<ILicenseReport[]>;
  downloadAuthReportRequest: IHttpRequestResult<void>;
  generateUserAssignmentsReportRequest: IHttpRequestResult<IReportStatusData>;
  generateMachineAssignmentsReportRequest: IHttpRequestResult<IReportStatusData>;
  generateApplicationAssignmentsReportRequest: IHttpRequestResult<IReportStatusData>;
  generateLicensingReportRequest: IHttpRequestResult<IReportStatusData>;
}

const initialState: IReportsState = {
  reports: null,
  licenses: [],
  users: [],
  selectedUsers: [],
  selectedRange: 'Last 7 days',
  getAssignmentsReportsRequest: createHttpRequestInitResult(),
  getReportStatusDataRequest: createHttpRequestInitResult(),
  getLoginReportsRequest: createHttpRequestInitResult(),
  getUsersReportsRequest: createHttpRequestInitResult(),
  getLicensesReportsRequest: createHttpRequestInitResult(),
  downloadAuthReportRequest: createHttpRequestInitResult(),
  generateUserAssignmentsReportRequest: createHttpRequestInitResult(),
  generateMachineAssignmentsReportRequest: createHttpRequestInitResult(),
  generateApplicationAssignmentsReportRequest: createHttpRequestInitResult(),
  generateLicensingReportRequest: createHttpRequestInitResult(),
};

export const reports = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    updateSelectedRange: (state, action: PayloadAction<string>) => {
      state.selectedRange = action.payload;
    },
    updateSelectedUsers: (state, action: PayloadAction<string[]>) => {
      state.selectedUsers = action.payload;
    },
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses<IReportsState>(
      builder,
      getAssignmentsReports,
      'getAssignmentsReportsRequest',
      (state, action: PayloadAction<IAssignmentsReports>) => {
        state.reports = action.payload;
      },
    );
    createExtraReducersForResponses<IReportsState>(builder, getReportStatusData, 'getReportStatusDataRequest');
    createExtraReducersForResponses<IReportsState>(builder, getLoginReports, 'getLoginReportsRequest');
    createExtraReducersForResponses<IReportsState>(
      builder,
      getUsersReports,
      'getUsersReportsRequest',
      (state, action: PayloadAction<IUsersReport[]>) => {
        state.users = action.payload;
        state.selectedUsers = action.payload.map((i) => i.userId);
      },
    );
    createExtraReducersForResponses<IReportsState>(
      builder,
      getLicensesReports,
      'getLicensesReportsRequest',
      (state, action: PayloadAction<ILicenseReport[], any, { arg: { domainId: string } }>) => {
        const data = action.payload;
        const { domainId } = action.meta.arg;
        state.licenses = data.sort((a, b) => (a.domainId === domainId ? -1 : b.domainId === domainId ? 1 : 0));
      },
    );
    createExtraReducersForResponses<IReportsState>(builder, downloadAuthReport, 'downloadAuthReportRequest');
    createExtraReducersForResponses<IReportsState>(
      builder,
      generateUserAssignmentsReport,
      'generateUserAssignmentsReportRequest',
      (state, action: PayloadAction<IReportStatusData>) => {
        if (state.reports) {
          state.reports = {
            ...state.reports,
            userAssignments: action.payload,
          };
        }
      },
    );
    createExtraReducersForResponses<IReportsState>(
      builder,
      generateMachineAssignmentsReport,
      'generateMachineAssignmentsReportRequest',
      (state, action: PayloadAction<IReportStatusData>) => {
        if (state.reports) {
          state.reports = {
            ...state.reports,
            machineAssignmnets: action.payload,
          };
        }
        return state;
      },
    );
    createExtraReducersForResponses<IReportsState>(
      builder,
      generateApplicationAssignmentsReport,
      'generateApplicationAssignmentsReportRequest',
      (state, action: PayloadAction<IReportStatusData>) => {
        if (state.reports) {
          state.reports = {
            ...state.reports,
            appAssignments: action.payload,
          };
        }
        return state;
      },
    );
    createExtraReducersForResponses<IReportsState>(builder, generateLicensingReport, 'generateLicensingReportRequest');
  },
});

export const { updateSelectedRange, updateSelectedUsers } = reports.actions;

export default reports.reducer;

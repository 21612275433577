import { Button, Table } from 'components';
import { IDomain, IQueryConfig } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import { getAppAssignments, IResourceAssignmentsItem } from 'store/slices/resourceAssignments';
import { IModalData } from 'pages/ResourceAssignments/ResourceAssignments';
import styles from './AppAssignmentsTable.module.scss';

interface Props {
  domain: IDomain;
  openManageAssignmentsModal: (data: IModalData) => void;
}

const AppAssignmentsTable = ({ domain, openManageAssignmentsModal }: Props) => {
  const dispatch = useAppDispatch();
  const appAssignments = useAppSelector((state) => state.resourceAssignments.appAssignmentsData);
  const queryViewBy = appAssignments?.queryConfig?.viewBy as string;

  const columns = [
    {
      name: queryViewBy === 'Users' ? 'User' : 'App',
      sortable: 'name',
      width: '38%',
      data: ({ name }: { name: string }) => name || 'N/A',
    },
    {
      name: queryViewBy === 'Users' ? 'Domain / UPN' : 'App Group',
      sortable: 'domainName',
      width: '38%',
      data: ({ domainName }: { domainName: string }) => domainName || 'N/A',
    },
    {
      name: `Assigned ${queryViewBy === 'Users' ? 'Apps' : 'Users'}`,
      width: '24%',
      data: (assigment: IResourceAssignmentsItem) => (
        <div>
          <span>{assigment.assignmentsCount}</span>
          <Button
            type="button"
            variant="primary"
            size="32"
            id="manage-assignments"
            className={styles.assignmentsButton}
            onClick={() =>
              openManageAssignmentsModal({
                type: 'manage',
                assignType: 'App',
                domainId: domain.id,
                viewBy: queryViewBy,
                resourceName: assigment.name || 'N/A',
                resourceId: assigment.resourceId,
                isPooled: assigment.isPooled,
              })
            }>
            Manage assignments
          </Button>
        </div>
      ),
    },
  ];

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getAppAssignments({ domainId: domain.id, query: query })).unwrap();
  };

  return (
    <Table<IResourceAssignmentsItem>
      columns={columns}
      data={appAssignments}
      dataKey="resourceId"
      keyWithIndex
      className={styles.assignmentsTable}
      dispatchAction={dispatchAction}
    />
  );
};

export default AppAssignmentsTable;
